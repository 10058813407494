import React, { useEffect, useContext, useRef, useState, Fragment } from "react";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo/seo";
import Layout from "../../components/layout/layout";
import PageBanner from "../../components/common/PageBanner";
import ImagePreview from '../../components/common/ImagePreview';
import Sidebar from '../../components/common/ArticleSidebar'
import SocialMedia from '../../components/common/SocialMedia'
import empty from '../../assets/img/icons/empty.png'
import ogImg from '../../assets/img/pages/articles/VIRSAT@ADIPEC.jpg'
import lsrAware from "../../assets/img/pages/newsletter/1/VIRSAT Life Saving Rules Aware.jpg"
import lsrPro from "../../assets/img/pages/newsletter/1/Virsat Life Saving Rules Pro.jpg"
import virsatLocation from "../../assets/img/pages/newsletter/1/Virsat Adipec 2022 Location.jpg"
import registration from "../../assets/img/pages/newsletter/1/Virsat Adipec 2022 Registration Steps.jpg"

const VirsatAdipec2022 = ({location}) => {
  const [pageTitle] = useState('VIRSAT Brings New Virtual Reality Training in ADIPEC 2022')
  const state = useContext(GlobalStateContext);
  let container = useRef(null);
  const slug = useState(location.pathname.split('/')[2]);
  let endDiv = useRef(null);
  const [endDivPosition, setEndDivPosition] = useState(0);

  const [showImgPrev,setShowImgPrev]=useState(false)
  const [activeImg,setActiveImg]=useState(empty)

  const handeleActiveImg = (img)=> {
    setActiveImg(img)
    setShowImgPrev(true)
  }

  const updatePosition = () => {
    setEndDivPosition(endDiv.current.getBoundingClientRect().top)
  };

  useEffect(() => {
    window.addEventListener("scroll", updatePosition);
    
    return () => window.removeEventListener("scroll", updatePosition);
  }, [endDivPosition]);

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  return (
    <Fragment>
      <ImagePreview img={activeImg} orientation="landscape" onClick={()=>setShowImgPrev(!showImgPrev)} show={showImgPrev}/>
      <Layout>
        <Seo
          title={pageTitle}
          description='Virtual Reality Safety Training (VIRSAT) is pleased to announce its participation in ADIPEC to launch a set of its new Virtual Reality innovations and products.'
          image={ogImg}
          ogType='article'
        />
        <PageBanner title='News' useDiv={true}/>
        <section
          className='main-container single-article hide-container'
          ref={container}
        >
          <div className='row less-mar bottom-pad'>
            <SocialMedia 
              pageUrl={location.href} 
              pageTitle={pageTitle}
              endDivPosition={endDivPosition}
            />
            {/* Article */}
            <article className='column article-details'>
              <header>
                <h1 className="main-title small primary-color">
                  <strong>VIRSAT Brings New Virtual Reality Training in ADIPEC</strong>
                </h1>
                <div className='author-date'>
                  <span></span>October 24, 2022
                  <span className='spacer'>|</span>2 minutes read
                </div>
              </header>
              <div 
                onClick={()=>handeleActiveImg(ogImg)} 
                aria-hidden className='article-main-image'>
                  <StaticImage
                    src='../../assets/img/pages/articles/VIRSAT@ADIPEC.jpg'
                    alt='VIRSAT Brings New Virtual Reality Training in ADIPEC'
                    title='VIRSAT Brings New Virtual Reality Training in ADIPEC'
                    quality="100"
                    objectFit
                    className='gatsby-image'
                  />
              </div>
              <p className="justify">
              Virtual Reality Safety Training (VIRSAT) is pleased to announce its participation in <a href="https://www.adipec.com/">ADIPEC</a> to launch a set of its new Virtual Reality innovations and products.
              </p>

              <p>VIRSAT's core business is helping O&G companies battle their onsite safety requirements using innovative methods and techniques to pass its training to workers regardless of their work profession and location in the organization. VIRSAT focuses on developing safety training solution software and games using Virtual Reality and 3D Technologies. It is among the very few companies worldwide to develop the Life-Saving Rules Serious Games based on the International Association of Oil and Gas Producers' (IOGP) standards and a long history of incident investigations and findings worldwide.  VIRSAT has recently established its presence in Abu Dhabi through building strong partnership with <a href="https://fms-tech.com">FMS Tech.</a> and its subsidiary Black Box Integrated Systems, LLC, a company based in Abu Dhabi, to serve the UAE and the GCC markets.</p>
              <p>During ADIPEC, VIRSAT will demonstrate the following set of products: <strong>Life-Saving RulesAWARE® , Life-Saving RulesPRO®, Life-Saving Rules Graded Road DrivingPRO, and Life-Saving Rules Defensive DrivingPRO®</strong>. While <strong>Life-Saving RulesAWARE®</strong> gives the worker the opportunity to learn and review the basic health and safety rules as detailed by IOGP and other leading O&G producers, <strong>Life-Saving RulesPRO®</strong> allows the driver to take the role of a manager or supervisor where he follows the worker and lets him intervene when he feels there is a safety violation committed.</p>

              <div className="flex adipec-virsat-apps">
                <div 
                  onClick={()=>handeleActiveImg(lsrAware)} 
                  aria-hidden className='article-image'>
                    <StaticImage
                      src='../../assets/img/pages/newsletter/1/VIRSAT Life Saving Rules Aware.jpg'
                      alt='VIRSAT Life Saving Rules Aware'
                      title='VIRSAT Life Saving Rules Aware'
                      quality="100"
                      objectFit
                      className='gatsby-image'
                    />
                </div>
                <div className="middle-column"/>
                <div 
                  onClick={()=>handeleActiveImg(lsrPro)} 
                  aria-hidden className='article-image'>
                    <StaticImage
                      src='../../assets/img/pages/newsletter/1/Virsat Life Saving Rules Pro.jpg'
                      alt='Virsat Life Saving Rules Pro'
                      title='Virsat Life Saving Rules Pro'
                      quality="100"
                      objectFit
                      className='gatsby-image'
                    />
                </div>
              </div>

              <p>For the first time in VR Training, Virtual Reality Safety Training, LLC (VIRSAT) will introduce in ADIPEC the latest in its line of Virtual Reality Training modules that is focused on driving on graded road. It has been reported that road accidents comprise a huge chunk of work-related fatalities, and employers are in constant search for solutions on how to reduce these. VIRSAT's Road Safety Experts and VR Developers developed a module focusing mainly on graded roads such as the desert.</p>

              <p>Similar to the two preceding modules, <strong>Graded Road DrivingPRO® and Defensive DrivingPRO</strong> are Virtual Reality serious games where the trainees join a day in the life of a driver. They follow the driver and need to intervene when a non-compliance is noticed. Playing the game ensures that the trainee knows, can and will apply the rules. These products also take into consideration all the standard Oil and Gas driving policies on graded and desert roads and all key graded road rules are addressed, as well as important vehicle requirements and precautions.</p>

              <p className="mar-bottom">Click these images to have a sneak peak of the <strong>Graded Road DrivingPRO®</strong> Module.</p>

              <a href="https://vimeo.com/746758188" target='_blank' rel="noreferrer">
                <div 
                  aria-hidden className='article-image anchor'>
                    <StaticImage
                      src='../../assets/img/pages/newsletter/1/VIRSAT Graded Road Driving Pro.jpg'
                      alt='VIRSAT Graded Road Driving Pro'
                      title='VIRSAT Graded Road Driving Pro'
                      quality="100"
                      objectFit
                      className='gatsby-image'
                    />
                </div>
              </a>

              <div className="flex adipec-virsat-apps">
                <a href="https://vimeo.com/746887026" target='_blank' rel="noreferrer" 
                  aria-hidden className='article-image anchor'>
                    <StaticImage
                      src='../../assets/img/pages/newsletter/1/VIRSAT Graded Road Driving Pro Journey Management.jpg'
                      alt='VIRSAT Graded Road Driving Pro Journey Management'
                      title='VIRSAT Graded Road Driving Pro Journey Management'
                      quality="100"
                      objectFit
                      className='gatsby-image'
                    />
                </a>
                <div className="middle-column"/>
                <a href="https://vimeo.com/746770787" target='_blank' rel="noreferrer" 
                  aria-hidden className='article-image anchor'>
                    <StaticImage
                      src='../../assets/img/pages/newsletter/1/VIRSAT Graded Road Driving Pro Roll Over Prevention.jpg'
                      alt='VIRSAT Graded Road Driving Pro Roll Over Prevention'
                      title='VIRSAT Graded Road Driving Pro Roll Over Prevention'
                      quality="100"
                      objectFit
                      className='gatsby-image'
                    />
                </a>
              </div>

              <p>During ADIPEC, customers will get the chance to experience the VR Training and see how this new technology can open new possibilities and add value to their workforce. VIRSAT's experts will also be available to answer questions and provide in-depth information on the modules.</p>

              <div className="separator-line"/>

              <div 
                onClick={()=>handeleActiveImg(virsatLocation)}
                aria-hidden className='article-image'>
                  <StaticImage
                    src='../../assets/img/pages/newsletter/1/Virsat Adipec 2022 Location.jpg'
                    alt='Virsat Adipec Location'
                    title='Virsat Adipec Location'
                    quality="100"
                    objectFit
                    className='gatsby-image'
                  />
              </div>

              <p className="mar-bottom">Register now at the ADIPEC website to secure your complimentary visitor pass. Click below to begin your registration.</p>

              <Link to="https://www.adipec.com/visit/registration/" className="button-link secondary full-width center">Register as a Visitor</Link>

              <p>If you wish to have a one-on-one meeting with our professionals, you may send an email to <a href="mailto:info@virsat.com">info@virsat.com</a>, or download the ADIPEC mobile app</p>

              <div className="flex adipec-virsat-apps">
                <a href="https://play.google.com/store/apps/details?id=com.swapcard.apps.android.adipec" target='_blank' rel="noreferrer" 
                  aria-hidden className='article-image anchor'>
                    <StaticImage
                      src='../../assets/img/pages/newsletter/1/Download Adipec Mobile App on Google.png'
                      alt='Download Adipec Mobile App on Google'
                      title='Download Adipec Mobile App on Google'
                      quality="100"
                      objectFit
                      className='gatsby-image'
                    />
                </a>
                <div className="middle-column"/>
                <a href="https://apps.apple.com/app/id1588420850" target='_blank' rel="noreferrer" 
                  aria-hidden className='article-image anchor'>
                    <StaticImage
                      src='../../assets/img/pages/newsletter/1/Download Adipec Mobile App on Apple.png'
                      alt='Download Adipec Mobile App on Apple'
                      title='Download Adipec Mobile App on Apple'
                      quality="100"
                      objectFit
                      className='gatsby-image'
                    />
                </a>
              </div>

              <p className="mar-bottom">and follow the steps below to book a time slot for a meeting request during the event.</p>

              <div 
                onClick={()=>handeleActiveImg(registration)}
                aria-hidden className='article-image'>
                  <StaticImage
                    src='../../assets/img/pages/newsletter/1/Virsat Adipec 2022 Registration Steps.jpg'
                    alt='Virsat Adipec Registration Steps'
                    title='Virsat Adipec Registration Steps'
                    quality="100"
                    objectFit
                    className='gatsby-image'
                  />
              </div>


              <div style={{height:'1px','width':'1px'}} ref={endDiv}/>
            </article>
            {/* End: Article */}

            {/* Sidebar */}
            <Sidebar slug={slug} endDivPosition={endDivPosition} type="news"/>
          </div>
        </section>
      </Layout>
    </Fragment>
  )
}

export default VirsatAdipec2022